body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;500;600;700&family=Lato:wght@400;700&family=Signika+Negative:wght@400;500;600;700&display=swap');

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: 'Josefin Sans', sans-serif;
  font-family: 'Lato', sans-serif;
  font-family: 'Signika Negative', sans-serif;
}

body {
  color: rgb(var(--foreground-rgb));
  font-family: 'Josefin Sans', sans-serif;
  font-family: 'Lato', sans-serif;
  font-family: 'Signika Negative', sans-serif;
  /* background: linear-gradient(
      to bottom,
      transparent,
      rgb(var(--background-end-rgb))
    )
    rgb(var(--background-start-rgb)); */
}

a {
  color: inherit;
  text-decoration: none;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}


*, *:before, *:after {
  box-sizing: border-box;
  font-family: 'Josefin Sans', sans-serif;
  font-family: 'Lato', sans-serif;
  font-family: 'Signika Negative', sans-serif;
}

body {
  margin: 0;
  font-family: 'Josefin Sans', sans-serif;
  font-family: 'Lato', sans-serif;
  font-family: 'Signika Negative', sans-serif;
  font-size: 15px;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

p {
  margin: 0 0 5px;
}

.container {
  max-width: 1230px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
}

.btn {
  min-width: 200px;
  height: 50px;
  padding: 0 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  background-color: #DB2512;
  transition: background-color .2s ease;
  cursor: pointer;
  outline: 0;
  border: 0;
}

.btn:hover {
  background-color: #b2190b;
}

/* Header start */
.header {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 3;
  box-sizing: border-box;
}

.header__wrapper {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.header__block {
  width: 20%;
}

.header__logo {
    opacity: 0.7;
}

.header__logo img {
  width:80%;
  height: 115px;
}

.nav {
  display: flex;
}

.nav__link {
  margin-right: 35px;
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  position: relative;
}

.nav__link:after {
  width: 100%;
  height: 2px;
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  content: '';
  opacity: 0;
  transition: opacity .2s ease;
}

.nav__link:last-child {
  margin-right: 0;
}

.nav__link:hover:after {
  opacity: 1;
}

.nav__link.active:after {
  opacity: 1;
}

.header__lng {
  display: flex;
  justify-content: flex-end;
}

.header__lng-link {
  margin-right: 8px;

  font-weight: 600;
  color: #B7B7B7;
  text-transform: uppercase;
  text-decoration: none;
  transition: color .2s ease;
}

.header__lng-link:last-child {
  margin-right: 0;
}

.header__lng-link:hover {
  color: #fff;
}

.header__lng-link.active {
  color: #fff;
}

/* Header end */

/*Intro start*/
.intro {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.intro:after {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;

  background-color: #000;
  opacity: .2;
  content: '';

}

.intro__content {
  height: 750px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
}

.intro__text {
  width: 500px;
  color: #fff;
}

.intro__block {

}

.intro__title {
  max-width: 790px;
  margin-bottom: 30px;
  font-size: 48px;
  font-weight: 700;
  color: white;
  line-height: 1.5;
  text-transform: uppercase;
}

/*Intro end*/

.section {
  margin-bottom: 100px;
}

.section.process {
  padding-top: 50px;
}

/*Info start*/

.info {
  transform: translateY(-50%);
}

.info__item {
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  padding: 40px 40px 30px;
}

.info__img {
  margin-bottom: 20px;
}

.info__img img {
  height: 30px;
}

.info__title {
  margin-bottom: 10px;
  font-size: 18px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

.info__text {
  font-size: 18px;
  color: #B7B7B7;
}

/*Info end*/

/*block start*/
.block__title {
  margin-bottom: 15px;
  font-size: 30px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

.block__title.text-white {
  color: #fff;
}

.block__link {
  margin-bottom: 60px;
  display: inline-block;
  letter-spacing: 0.15em;
  color: #000;
  text-transform: uppercase;
}

.block__link:hover {
  text-decoration: none;
}

.wrapper {
  display: flex;
  margin: 0 -10px;
}

.block {
  width: 33.33%;
  padding: 0 10px;
}

.text-upper {
  text-transform: uppercase;
}

/*block end*/


/*services start*/

.services__item {
  text-decoration: none;
  opacity: 1;
}

.services__item:hover .services__content-item {
  opacity: 1;
}

.services__item:hover .services__content:after {
  opacity: .7;
}

.services__content {
  height: 500px;
  margin-bottom: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.services__content:after {
  width: 100%;
  height: 100%;
  background-color: gray;
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  transition: opacity .2s ease;
}

.services__content-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 2;
  transition: opacity .2s ease;
}

.services__img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #DB2512;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.services__title {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.23em;
  color: #000;
  text-transform: uppercase;
}

.services__text {
  max-width: 270px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  letter-spacing: 0.23em;
  position: absolute;
  left: 30px;
  bottom: 20px;
  text-transform: uppercase;
}

/*services end*/

/*about start*/
.about {
  padding: 115px 0;
  background-color: #235c29;
}

.about__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.about__block {
  max-width: 505px;
}

.about__text {
  margin: 35px 0 40px;
  font-weight: 600;
  color: #B7B7B7;
  letter-spacing: 0.23em;
}

.about__link {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.15em;
  text-transform: uppercase;
}

.about__link:hover {
  text-decoration: none;
}

.about__img {
  height: 340px;
}

/*about end*/

/*process start*/
.process__title {
  margin-bottom: 50px;
  font-size: 70px;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
}

.process__subtitle {
  margin-bottom: 90px;
  font-size: 40px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  text-align: center;
}

.process__item {
  padding: 30px 30px 15px;
  height: 100%;
  min-height: 150px;
  background: #FFFFFF;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
}

/*process end*/

/*contacts start*/
.contacts {
  padding: 100px 0;
  background-color: #171616;
}

.contacts .block__title {
  margin-bottom: 40px;
}

.contacts__form {
  max-width: 440px;
}

.form__row {
  margin: 0 -20px;
  display: flex;
}

.form__group {
  margin-bottom: 30px;
  padding: 0 20px;
  flex: 1;
  position: relative;
}

.input {
  width: 100%;
  padding: 9px 0;
  outline: 0;
  border: 0;
  color: #fff;
  font-family: inherit;
  font-size: 15px;
  border-bottom: 1px solid #fff;
  background-color: transparent;
}

.input:focus + .label {
  transform: translateY(-15px);
  font-size: 12px;
}

.input:not(:placeholder-shown) + .label {
  transform: translateY(-15px);
  font-size: 12px;
}

.label {
  letter-spacing: 0.15em;
  color: #fff;
  position: absolute;
  top: 10px;
  left: 20px;
  pointer-events: none;
  transition: all .2s ease;
}

.textarea {
  min-height: 130px;
  width: 100%;
  padding: 9px 0;
  resize: vertical;
  outline: 0;
  border: 0;
  color: #fff;
  font-family: inherit;
  font-size: 15px;
  border-bottom: 1px solid #fff;
  background-color: transparent;
}

.textarea:focus + .label {
  transform: translateY(-15px);
  font-size: 12px;
}

.textarea:not(:placeholder-shown) + .label {
  transform: translateY(-15px);
  font-size: 12px;
}

/*contacts end*/


/*footer start*/
.footer {

}

.footer__wrapper {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.copyright {
  letter-spacing: 0.15em;
}

.footer__soc {
  display: flex;
}

.footer__soc-link {
  margin-right: 20px;
  text-decoration: none;
}

.footer__soc-link:last-child {
  margin-right: 0;
}

.footer__soc-link img {
  height: 25px;
}
/*footer end*/



@media (max-width: 1260px) {
  .info__item {
    padding: 30px;
  }

  .info__title {
    font-size: 16px;
  }

  .info__text {
    font-size: 14px;
  }
}

@media (max-width: 1080px) {
  .info__title {
    letter-spacing: unset;
  }

  .about__block {
    padding-right: 30px;
  }

  .services__content {
    height: 400px;
  }
}

@media (max-width: 992px) {

  .section {
    margin-bottom: 30px;
  }

  .header__logo img {
    height: 55px;
  }

  .intro__content {
    height: 530px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
  }
  .intro__text {
    width: 300px;
    color: #fff;
  }


  .intro__title {
    font-size: 34px;
  }

  .intro__text {
    width: 500px;
    color: #fff;
  }

  .info__item {
    padding: 20px;
  }

  .services__content {
    height: 300px;
  }

  .services__content-item {

  }

  .services__content:after {

  }

  .about {
    padding: 30px 0;
  }

  .contacts {
    padding: 30px 0;
  }

  .about__wrapper {
    flex-direction: column;
  }

  .about__block {
    padding-right: 0;
    margin-bottom: 30px;
  }

  .about__img {
    height: auto;
    max-width: 100%;
  }

  .about__text {
    margin: 20px 0 30px;
    text-align: justify;
  }

  .about__link {
    font-size: 14px;
  }

  .section.process {
    padding-top: 0;
  }

  .process__title {
    margin-bottom: 10px;
    font-size: 60px;
  }

  .process__subtitle {
    margin-bottom: 30px;
  }

  .process__item {
    text-align: center;
    min-height: auto;
    padding: 20px 20px 12px;
  }

}

@media (max-width: 850px) {
  .nav {
    font-size: 13px;
  }
  .info {
    transform: translateY(-75px);
    margin-bottom: -40px;
  }
  .intro__title {
    width: 400px;
    font-size: 24px;
  }

  .wrapper {
    flex-wrap: wrap;
  }

  .block {
    width: 100%;
    margin-bottom: 20px;
  }

  .block__title {
    text-align: center;
  }


  .info__item {
    text-align: center;
  }

  .block__link {
    display: flex;
    justify-content: center;
  }

  .services__content {
    height: 450px;
    background-position: center;
  }

  .contacts__form {
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .nav {
    font-size: 12px;
  }
  .intro__title {
    width: 400px;
    font-size: 20px;
  }
  .intro__text {
    width: 400px;
    color: #fff;
    font-size: 13px;
  }

}


@media (max-width: 560px) {
  .nav {
    font-size: 10px;
  }
  .header__logo img {
    height: 45px;
  }

  .intro__title {
    width: 300px;
    font-size: 16px;
  }
  .intro__text {
    width: 300px;
    color: #fff;
    font-size: 12px;
  }
  .services__content {
    height: 300px;
  }

  .process__title {
    font-size: 50px;
  }

  .process__subtitle {
    font-size: 25px;
  }

  .footer__wrapper {
    height: auto;
    padding: 30px 0;
    flex-direction: column;
  }

  .copyright {
    margin-bottom: 20px;
  }

  .form__row {
    margin: 0 -10px;
    flex-direction: column;
  }

  .form__group {
    padding: 0 10px;
  }
}


@media (max-width: 460px) {
  .nav {
    font-size: 8px;
  }
  .intro__title {
    width: 300px;
    font-size: 16px;
  }
  .intro__text {
    width: 300px;
    color: #fff;
    font-size: 12px;
  }

  .block__title {
    font-size: 24px;
  }

  .process__title {
    font-size: 20px;
  }

  .process__subtitle {
    font-size: 18px;
  }

  .copyright {
    text-align: center;
  }
}